<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-row>
        <b-col class='d-flex align-items-center'>
          <BackButton></BackButton>
          <h5 class='ml-2'>Clients List
            <b-link v-if='client' to='/sftp/clients/' />
          </h5>
        </b-col>
        <b-col class='d-flex justify-content-end'>
          <!-- Save eligibility file configs -->
          <b-button class='ml-3' @click='save' :disabled='saving' type='button' id='btn_save' variant='primary'>
            <b-spinner label='Loading...' variant='light' small v-if='loading'></b-spinner>
            <span v-else>Save Configuration</span>
          </b-button>
        </b-col>
      </b-row>
      <ClientGeneralConfig ref='client_general_configs' :clientConfigs='clientGeneralConfigurations' />
      <HooksConfig :clientHooks='clientHooks' :hooks='hooks' :hooksOptions='hooksOptions'
                   :clientHooksToRemove='clientHooksToRemove' />
      <ClientPushConfig :clientConfigs='clientPushConfigs' :clientConfigsToRemove='clientPushConfigsToRemove' />
      <ClientPullConfig :clientConfigs='clientPullConfigs' :clientConfigsToRemove='clientPullConfigsToRemove' />
    </div>
  </b-container>
</template>

<script>
import VueTextareaAutosize from 'vue-textarea-autosize';
import Vue from 'vue';
import moment from 'moment';
import * as R from 'ramda';
import ClientGeneralConfig from '@/components/SFTP/Client/Configs/ClientGeneralConfigs.vue';
import HooksConfig from '@/components/SFTP/Client/Configs/HooksConfig.vue';
import BackButton from '@/components/BackButton.vue';
import ClientPushConfig from '@/components/SFTP/Client/Configs/ClientPushConfig.vue';
import ClientPullConfig from '@/components/SFTP/Client/Configs/ClientPullConfig.vue';
import { isEqual } from 'lodash';
import { mapGetters } from 'vuex';

Vue.use(VueTextareaAutosize);

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'SFTPClientConfigs',
  components: {
    ClientGeneralConfig,
    HooksConfig,
    BackButton,
    ClientPushConfig,
    ClientPullConfig,
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    this.loading = true;
    try {
      this.client = await this.$store.dispatch('Sftp/Client/getClient', { clientId: this.$route.params.clientId });
      await Promise.all(
        [
          this.getClientConfigurations(),
          this.getClientHooks(),
          this.getClientPushConfig(),
          this.getClientPullConfig(),
        ],
      );
    } catch (err) {
      Vue.prototype.$noty.error(R.pathOr(err.message, [ 'response', 'data', 'message' ], err));
      console.error(err.message);
    }
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      saving: false,
      client: null,
      // -------------------------------------- CLIENT CONFIGS---------------------------------------- //
      clientGeneralConfigurations: {},
      // -------------------------------------- HOOKS CONFIGS---------------------------------------- //
      clientHooks: [],
      hooks: [],
      hooksOptions: [],
      clientHooksToRemove: [],
      // -------------------------------------- PULL CONFIGS ---------------------------------------- //
      clientPullConfigs: [],
      clientPullConfigsToRemove: [],
      // -------------------------------------- PUSH CONFIGS ---------------------------------------- //
      clientPushConfigs: [],
      clientPushConfigsToRemove: [],
    };
  },
  methods: {
    async getClientConfigurations() {
      try {
        const clientConfig = await this.$store.dispatch('Sftp/Client/getClient', { clientId: this.$route.params.clientId })
          .then(result => result.data);
        this.clientGeneralConfigurations = R.pick([
          'id',
          'client_folder',
          'encrypt_files',
          'public_key_code',
          'private_key_code',
          'working_type',
          'created_at',
          'updated_at',
          'compress_files',
          'encryption_use_sword_public_key',
          'encryption_file_format',
          'compression_file_format',
          'insertion_execution_order',
        ], clientConfig);

        this.clientGeneralConfigurations.encrypt_files = !!this.clientGeneralConfigurations.encrypt_files;
        this.clientGeneralConfigurations.compress_files = !!this.clientGeneralConfigurations.compress_files;
        this.clientGeneralConfigurations.encryption_use_sword_public_key = !!this.clientGeneralConfigurations.encryption_use_sword_public_key;
        this.clientGeneralConfigurations.insertion_execution_order = this.clientGeneralConfigurations.insertion_execution_order.split(',');
        return clientConfig;
      } catch (error) {
        const msg = R.pathOr(error.message, [ 'response', 'data', 'message' ], error);
        Vue.prototype.$noty.error(typeof msg === 'string' ? msg : JSON.stringify(msg));
        console.error(error.message);
        throw error;
      }
    },
    async getClientHooks() {
      const clientHooks = await this.$store.dispatch('Sftp/Client/getClientHooks', { clientId: this.$route.params.clientId })
        .then(result => result.data);
      // eslint-disable-next-line no-restricted-syntax
      for (const clientHook of clientHooks) {
        if (!isNullOrEmpty(clientHook.config)) {
          const currentHook = {
            client_id: clientHook.client_id,
            hook_id: clientHook.hook_id,
            config: isNullOrEmpty(clientHook.config)
              ? JSON.stringify({})
              : JSON.stringify(clientHook.config),
            is_active: clientHook.is_active,
            code: clientHook.code,
            description: clientHook.description,
            example: clientHook.example,
            created_at: moment(clientHook.created_at).format('YYYY-MM-DD HH:mm:ss'),
            updated_at: moment(clientHook.updated_at).format('YYYY-MM-DD HH:mm:ss'),
          };
          this.clientHooks.push(currentHook);
        }

        const hook = {
          id: clientHook.id,
          code: clientHook.code,
          description: clientHook.description,
          example: clientHook.example,
        };
        this.hooks.push(hook);

        const opt = {
          text: hook.code,
          value: hook.id,
          disabled: !isNullOrEmpty(clientHook.config),
        };
        this.hooksOptions.push(opt);
      }
    },
    async getClientPullConfig() {
      const clientPull = await this.$store.dispatch('Sftp/Client/getClientPullConfig', { clientId: this.$route.params.clientId })
        .then(result => result.data);

      // eslint-disable-next-line no-restricted-syntax
      for (const config of clientPull) {
        const obj = {
          client_id: config.client_id,
          auth_config_key: config.auth_config_key,
          is_active: !!config.is_active,
          scrape_config: isNullOrEmpty(config.scrape_config)
            ? JSON.stringify({})
            : JSON.stringify(config.scrape_config),
          delete_remote_files: !!config.delete_remote_files,
          created_at: moment(config.created_at).format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment(config.updated_at).format('YYYY-MM-DD HH:mm:ss'),
          lastExecutionDetails: config.lastExecutionDetails,
          allExecutionsDetails: config.allExecutionsDetails,
        };
        this.clientPullConfigs.push(obj);
      }
    },
    async getClientPushConfig() {
      this.clientPushConfigs = [];
      const clientPushConfig = await this.$store.dispatch('Sftp/Client/getClientPushConfig', { clientId: this.$route.params.clientId })
        .then(result => result.data);

      // eslint-disable-next-line no-restricted-syntax
      for (const config of clientPushConfig) {
        const obj = {
          uuid: config.uuid,
          client_id: config.client_id,
          type: config.type,
          auth_config_key: config.auth_config_key,
          configuration: isNullOrEmpty(config.configuration)
            ? JSON.stringify({})
            : JSON.stringify(config.configuration),
          is_active: !!config.is_active,
          created_at: moment(config.created_at).format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment(config.updated_at).format('YYYY-MM-DD HH:mm:ss'),
          lastExecutionDetails: config.lastExecutionDetails,
          allExecutionsDetails: config.allExecutionsDetails,
        };
        this.clientPushConfigs.push(obj);
      }
    },
    async save() {
      if (!this.validateInformation()) {
        return;
      }

      this.saving = true;
      try {
        await this.saveClientConfiguration();
        await this.saveHooks();
        await this.saveClientPullConfigs();
        await this.saveClientPushConfigs();
        await this.getClientConfigurations();
        await this.getClientHooks();
        await this.getClientPullConfig();
        await this.getClientPushConfig();

        this.$noty.success('Configurations saved');
      } catch (error) {
        const msg = R.pathOr(error.message, [ 'response', 'data', 'message' ], error);
        Vue.prototype.$noty.error(typeof msg === 'string' ? msg : JSON.stringify(msg));
        console.error(error.message);
      } finally {
        this.saving = false;
      }
    },
    async saveClientConfiguration() {
      if (!isNullOrEmpty(this.clientGeneralConfigurations)) {
        const toUpdate = R.pick([
          'encrypt_files',
          'public_key_code',
          'private_key_code',
          'working_type',
          'compress_files',
          'encryption_use_sword_public_key',
          'encryption_file_format',
          'compression_file_format',
        ], this.clientGeneralConfigurations);

        toUpdate.insertion_execution_order = this.clientGeneralConfigurations.insertion_execution_order.join(',');

        await this.$store.dispatch('Sftp/Client/updateClientConfigurations',
          {
            clientId: this.clientGeneralConfigurations.id,
            params: toUpdate,
          });
      }
    },
    async saveHooks() {
      // remove hooks if necessary
      // eslint-disable-next-line no-restricted-syntax
      for (const hook of this.clientHooksToRemove) {
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('Sftp/Client/deleteClientHook', { clientId: hook.client_id, hookId: hook.hook_id });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const hook of this.clientHooks) {
        const h = {
          client_id: hook.client_id,
          hook_id: hook.hook_id,
          config: JSON.parse(hook.config),
          is_active: hook.is_active,
          created_at: moment(hook.created_at).format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('Sftp/Client/saveClientHook', {
          clientId: h.client_id,
          hookId: h.hook_id,
          params: h,
        });
      }
    },
    async saveClientPullConfigs() {
      // remove configs if necessary
      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPullConfigsToRemove) {
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('Sftp/Client/deleteClientPullConfig', { clientId: config.client_id });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPullConfigs) {
        const cfg = {
          client_id: config.client_id,
          auth_config_key: config.auth_config_key,
          is_active: config.is_active,
          scrape_config: isNullOrEmpty(config.scrape_config) ? [] : JSON.parse(config.scrape_config),
          delete_remote_files: config.delete_remote_files,
          created_at: moment(config.created_at).format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        };

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('Sftp/Client/saveClientPullConfig', { clientId: cfg.client_id, params: cfg });
      }
    },
    async saveClientPushConfigs() {
      // remove configs if necessary
      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPushConfigsToRemove) {
        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('Sftp/Client/deleteClientPushConfig', {
          clientId: config.client_id,
          uuid: config.uuid,
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPushConfigs) {
        const cfg = {
          uuid: config.uuid,
          client_id: config.client_id,
          type: config.type,
          auth_config_key: config.auth_config_key,
          configuration: isNullOrEmpty(config.configuration) ? {} : JSON.parse(config.configuration),
          is_active: config.is_active,
          created_at: moment(config.created_at).format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        };

        if (!config.uuid) {
          const data = R.omit([ 'uuid' ], cfg);
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('Sftp/Client/createClientPushConfig', { clientId: cfg.client_id, params: data });
        } else {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('Sftp/Client/updateClientPushConfig', {
            clientId: cfg.client_id,
            params: cfg,
            hookId: cfg.uuid,
          });
        }
      }
    },
    validateInformation() {
      if (this.$refs.client_general_configs.$v.$invalid) {
        Vue.prototype.$noty.error('Invalid client configuration');
        return false;
      }
      if (isNullOrEmpty(this.clientGeneralConfigurations.private_key_code)) {
        Vue.prototype.$noty.error('Missing Private Key Code');
        return false;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const h of this.clientHooks) {
        const defaultConfig = this.hooks.find(hook => hook.id === h.hook_id);
        if (!defaultConfig) {
          Vue.prototype.$noty.error('Internal error');
          return false;
        }

        if (isNullOrEmpty(h.config)) {
          Vue.prototype.$noty.error(`${h.code} as an invalid configuration`);
          return false;
        }

        if (isEqual(JSON.parse(h.config), defaultConfig.example)) {
          Vue.prototype.$noty.error(`${h.code} has the default hook configuration.`);
          return false;
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPullConfigs) {
        if (isNullOrEmpty(JSON.parse(config.scrape_config))) {
          Vue.prototype.$noty.error('Client pull config has an invalid configuration');
          return false;
        }

        if (isNullOrEmpty(config.auth_config_key)) {
          Vue.prototype.$noty.error('Missing auth config key in the client pull configuration');
          return false;
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const config of this.clientPushConfigs) {
        if (isNullOrEmpty(JSON.parse(config.configuration))) {
          Vue.prototype.$noty.error('Client push config has an invalid configuration');
          return false;
        }

        if (isNullOrEmpty(config.auth_config_key)) {
          Vue.prototype.$noty.error('Missing auth config key in the client push configuration');
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped>

</style>
