<template>
  <b-container>
    <b-row class='mt-4'>
      <b-col>
        <b-card class='mb-4'>
          <template #header>
            <h5>General Configuration</h5>
          </template>
          <b-row class='mt-4 mb-2 ml-1' cols='12' v-if='!validData'>
            <h5>Empty Configuration</h5>
          </b-row>
          <div v-else>
            <b-row class='mt-4' cols='12'>
              <b-col cols='3' class='mt-2'>
                <label>Client Folder: </label>
              </b-col>
              <b-col cols='9'>
                <b-form-input type='text' v-model='clientConfigs.client_folder' disabled />
              </b-col>
            </b-row>
            <b-row class='mt-4' cols='12'>
              <b-col cols='3' class='mt-2'>
                <label>Working Type: </label>
              </b-col>
              <b-col cols='9'>
                <b-form-select v-model='clientConfigs.working_type' :options='workingTypeOptions'></b-form-select>
              </b-col>
            </b-row>
            <b-row class='mt-4' cols='12'>
              <b-col cols='3' class='mt-2'>
                <label>Private Key Code: </label>
              </b-col>
              <b-col cols='9'>
                <b-form-input type='text' v-model='clientConfigs.private_key_code' />
              </b-col>
            </b-row>
            <b-row class='mt-4'>
              <b-col cols='12'>
                <label>Encryption and Compression</label>
                <b-button title='Switch Order' v-b-tooltip.hover @click='switchOrder()'
                          class='float-right' variant='link' style='min-width: unset; padding: 0'
                          :id="'btn-remove-config-'">
                  <feather type='repeat'></feather>
                </b-button>
                <b-row>
                  <b-col>
                    <hr>
                  </b-col>
                </b-row>
                <div>
                  <b-card
                    no-body
                    class='mb-2'
                    v-for='item in clientConfigs.insertion_execution_order'
                    :key='item.value'>
                    <b-card-header :header='formatHeader(item)' v-b-toggle="'collapse-extraction-' + item">
                    </b-card-header>
                    <b-collapse :id="'collapse-extraction-' + item">
                      <template v-if='item === "encryption"'>
                        <b-row cols='12'>
                          <b-col cols='12'>
                            <b-row cols='12' class='ml-4 mt-4'>
                              <b-col cols='3'>
                                <label>Active: </label>
                              </b-col>
                              <b-col cols='1'>
                                <b-form-group
                                  id='encrypt-files'>
                                  <b-form-checkbox v-model='clientConfigs.encrypt_files'></b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <div v-if='clientConfigs.encrypt_files'>
                              <b-row cols='12' class='ml-4'>
                                <b-col cols='3'>
                                  <label>Encrypt with Sword key: </label>
                                </b-col>
                                <b-col cols='1'>
                                  <b-form-group
                                    id='encryption-sword-key'>
                                    <b-form-checkbox
                                      v-model='clientConfigs.encryption_use_sword_public_key'></b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row cols='12' class='ml-4'>
                                <b-col cols='3' class='mt-2'>
                                  <label>Client key code: </label>
                                </b-col>
                                <b-col cols='9'>
                                  <b-form-group
                                    id='encryption-public-key-code'
                                    :invalid-feedback="handleError('clientConfigs.public_key_code')"
                                    :state="isValid('clientConfigs.public_key_code')">
                                    <b-form-input type='text' v-model='clientConfigs.public_key_code' />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row cols='12' class='ml-4'>
                                <b-col cols='3' class='mt-2'>
                                  <label>File format: </label>
                                </b-col>
                                <b-col cols='9'>
                                  <b-form-group
                                    id='encryption-file-format'
                                    :invalid-feedback="handleError('clientConfigs.encryption_file_format')"
                                    :state="isValid('clientConfigs.encryption_file_format')">
                                    <b-form-select
                                      v-model='clientConfigs.encryption_file_format'
                                      :options='encryptionFileFormatOptions'
                                      :required=true>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-if='item === "compression"'>
                        <b-row class='mt-1' cols='12'>
                          <b-col cols='12'>
                            <b-row cols='12' class='ml-4 mt-4'>
                              <b-col cols='3'>
                                <label>Active: </label>
                              </b-col>
                              <b-col cols='1'>
                                <b-form-group
                                  id='compression'>
                                  <b-form-checkbox v-model='clientConfigs.compress_files'></b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <div v-if='clientConfigs.compress_files'>
                              <b-row cols='12' class='ml-4'>
                                <b-col cols='3'>
                                  <label>File format: </label>
                                </b-col>
                                <b-col cols='9'>
                                  <b-form-group
                                    id='compression-file-format'
                                    :invalid-feedback="handleError('clientConfigs.compression_file_format')"
                                    :state="isValid('clientConfigs.compression_file_format')">
                                    <b-form-select
                                      v-model='clientConfigs.compression_file_format'
                                      :options='compressionFileFormatOptions'
                                      :required=true>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                      </template>
                    </b-collapse>
                  </b-card>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row class='mb-2'>
                  <b-col cols='12' class='mb-2'>
                    <h5>Metadata:</h5>
                  </b-col>
                </b-row>
                <b-row class='mb-4 ml-2'>
                  <b-col cols='3'>
                    <label>Created At:</label>
                  </b-col>
                  <b-col cols='9'>
                    <span>{{ normalizeExecutionDetailDate(clientConfigs.created_at) }}</span>
                  </b-col>
                </b-row>
                <b-row class='mb-4 ml-2'>
                  <b-col cols='3'>
                    <label>Updated At:</label>
                  </b-col>
                  <b-col cols='9'>
                    <span>{{ normalizeExecutionDetailDate(clientConfigs.updated_at) }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as R from 'ramda';
import moment from 'moment';
import errorHandler from '@/mixins/errorHandler';
import { requiredIf } from 'vuelidate/lib/validators';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'GeneralConfigs',
  mixins: [ errorHandler ],
  props: {
    clientConfigs: {
      type: Object,
    },
  },
  validations() {
    return {
      clientConfigs: {
        public_key_code: {
          required: requiredIf(element => element.encrypt_files),
        },
        encryption_file_format: {
          required: requiredIf(element => element.encrypt_files),
        },
        compression_file_format: {
          required: requiredIf(element => element.compress_files),
        },
      },
    };
  },
  data() {
    return {
      clientPublicKey: true,
      validData: false,
      workingTypeOptions: [
        {
          value: 'pull',
          text: 'pull',
        },
        {
          value: 'push',
          text: 'push',
        },
      ],
      encryptionFileFormatOptions: [
        { value: 'gpg', text: 'gpg' },
        { value: 'pgp', text: 'pgp' },
      ],
      compressionFileFormatOptions: [
        { value: 'gzip', text: 'gzip' },
        { value: 'gz', text: 'gz' },
      ],
    };
  },
  beforeMount() {
    if (!isNullOrEmpty(this.clientConfigs)) {
      this.validData = true;
    }
  },
  methods: {
    normalizeExecutionDetailDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    switchOrder() {
      this.clientConfigs.insertion_execution_order = R.reverse(this.clientConfigs.insertion_execution_order);
    },
    formatHeader(item) {
      if (item === 'encryption') {
        return 'Encryption';
      }
      if (item === 'compression') {
        return 'Compression';
      }
      return '';
    },
  },
};
</script>

<style scoped>
</style>
