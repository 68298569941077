<template>
  <b-container>
    <b-row class='mt-4'>
      <b-col>
        <b-card class='mb-4'>
          <template #header>
            <h5>Client Pull Configuration</h5>
          </template>
          <b-col cols='12' class='mb-2' v-for='(config, index) in clientConfigs' :key="'config-' + index">
            <b-row>
              <b-col cols='12'>
                <b-row class="mt-4 mb-2">
                  <b-col cols='3' class="mt-2">
                    <label>Is Active: </label>
                  </b-col>
                  <b-col cols='3'>
                    <b-form-checkbox v-model="config.is_active" />
                  </b-col>
                  <b-col cols='3' class="mt-2">
                    <label>Delete Remote Files: </label>
                  </b-col>
                  <b-col cols='3'>
                    <b-form-checkbox v-model="config.delete_remote_files" />
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col cols='3' class="mt-2">
                    <label class='mr-3'>Auth Config Key: </label>
                  </b-col>
                  <b-col cols='9'>
                    <b-form-input type="text" v-model="config.auth_config_key" />
                  </b-col>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col>
                    <label class='mr-3'>Scrape Config: </label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols='6'>
                    <TextareaAutosize class='text-area' placeholder='Configuration' ref='myTextarea'
                      v-model='config.scrape_config' :min-height='200' />
                  </b-col>
                  <b-col cols='6'>
                    <b-card class='mb-4 pretty-text-area'>
                      <pre>{{ config.scrape_config | pretty }}</pre>
                    </b-card>
                  </b-col>
                </b-row>
                <b-row class="ml-1 mt-1 mb-2">
                  <b-col>
                    <b-button @click='prettyJson(index)'>JSON pretty</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-row class='ml-2 mr-2'>
                      <b-col>
                        <hr>
                      </b-col>
                    </b-row>
                    <b-row class='ml-2 mt-1 mb-2'>
                      <b-col>
                        <b-row class='mb-2'>
                          <b-col cols='12' class='mb-2'>
                            <h5>Metadata:</h5>
                          </b-col>
                        </b-row>
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3'>
                            <span>Created At:</span>
                          </b-col>
                          <b-col cols='9'>
                            <span>{{ config.created_at }}</span>
                          </b-col>
                        </b-row>
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3'>
                            <span>Updated At:</span>
                          </b-col>
                          <b-col cols='9'>
                            <span>{{ config.updated_at }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class='ml-2 mt-3 mb-3 mr-2' v-if="config.lastExecutionDetails">
                  <b-col cols='12' class='mb-2' v-for='(lastExecutionDetail, index) in config.lastExecutionDetails'
                    :key="'lastExecutionDetail-' + index">
                    <header class='card-header' v-b-toggle="'collapse-pull-config-last-execution-detail-' + index">
                      <b-row>
                        <strong>Last Execution Details</strong>
                      </b-row>
                    </header>
                    <b-collapse :id="'collapse-pull-config-last-execution-detail-' + index" class='mt-2 mb-2' style='
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        border-top: 0;
                        border-bottom-right-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;'>
                      <b-row class='mb-4 ml-2'>
                        <b-col cols='3'>
                          <span>Filename::</span>
                        </b-col>
                        <b-col cols='9'>
                          <span>{{ normalizeExecutionDetailString(lastExecutionDetail.filename) }}</span>
                        </b-col>
                      </b-row>
                      <b-row class='mb-4 ml-2'>
                        <b-col cols='3'>
                          <span>Folder:</span>
                        </b-col>
                        <b-col cols='9'>
                          <span>{{ normalizeExecutionDetailString(lastExecutionDetail.folder) }}</span>
                        </b-col>
                      </b-row>
                      <b-row class='mb-4 ml-2'>
                        <b-col cols='3' class="mt-2">
                          <span>Created At:</span>
                        </b-col>
                        <b-col cols='9' class="mt-2">
                          <span>{{ normalizeExecutionDetailDate(lastExecutionDetail.created_at) }}</span>
                        </b-col>
                      </b-row>
                      <b-row class='mb-4 ml-2'>
                        <b-col cols='3'>
                          <span>Updated At:</span>
                        </b-col>
                        <b-col cols='9'>
                          <span>{{ normalizeExecutionDetailDate(lastExecutionDetail.updated_at) }}</span>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row class='ml-2 mt-3 mb-3 mr-2' v-if="config.allExecutionsDetails">
                  <b-col>
                    <header class='card-header' v-b-toggle="'collapse-pull-config-execution-details-' + index">
                      <b-row>
                        <strong>All Execution Details</strong>
                      </b-row>
                    </header>
                    <b-collapse :id="'collapse-pull-config-execution-details-' + index" class='mt-2 mb-2' style='
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        border-top: 0;
                        border-bottom-right-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;'>
                      <b-col cols='12' class='mb-2' v-for='(executionDetail, index) in config.allExecutionsDetails'
                        :key="'executionDetail-' + index">
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3' class="mt-2">
                            <span>Created At:</span>
                          </b-col>
                          <b-col cols='9' class="mt-2">
                            <span>{{ normalizeExecutionDetailDate(executionDetail.created_at) }}</span>
                          </b-col>
                        </b-row>
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3'>
                            <span>Updated At:</span>
                          </b-col>
                          <b-col cols='9'>
                            <span>{{ normalizeExecutionDetailDate(executionDetail.updated_at) }}</span>
                          </b-col>
                        </b-row>
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3'>
                            <span>Filename::</span>
                          </b-col>
                          <b-col cols='9'>
                            <span>{{ normalizeExecutionDetailString(executionDetail.filename) }}</span>
                          </b-col>
                        </b-row>
                        <b-row class='mb-4 ml-2'>
                          <b-col cols='3'>
                            <span>Folder:</span>
                          </b-col>
                          <b-col cols='9'>
                            <span>{{ normalizeExecutionDetailString(executionDetail.folder) }}</span>
                          </b-col>
                        </b-row>
                        <b-row class='ml-2 mr-2'>
                          <b-col>
                            <hr>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class='d-flex justify-content-end mt-3'>
              <b-button class='mr-3' @click='removeConfig(index)' :disabled='!Boolean(clientConfigs.length)'>Remove
                Config</b-button>
              <b-button class='mr-3' @click='addConfig' :disabled='Boolean(clientConfigs.length)'>Add Config</b-button>
            </div>
          </b-col>
          <div v-if='!Boolean(clientConfigs.length)' class='d-flex justify-content-end mt-3'>
            <b-button class='mr-3' @click='addConfig'>Add Config</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import moment from 'moment';
import * as R from 'ramda';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'PullConfigs',
  props: {
    clientConfigs: {
      type: Array,
    },
    clientConfigsToRemove: {
      type: Array,
    },
  },
  filters: {
    // eslint-disable-next-line consistent-return
    pretty(value) {
      try {
        return JSON.stringify(JSON.parse(value), null, 2);
      } catch (e) {
        return 'Invalid JSON Input!!';
      }
    },
  },
  methods: {
    removeConfig(index) {
      this.clientConfigsToRemove.push(this.clientConfigs[index]);
      this.clientConfigs.splice(index, 1);
    },
    addConfig() {
      const obj = {
        client_id: this.$route.params.clientId,
        type: '',
        auth_config_key: '',
        configuration: null,
        is_active: false,
        delete_remote_files: false,
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      this.clientConfigs.push(obj);
    },
    prettyJson(index) {
      try {
        this.clientConfigs[index].scrape_config = JSON.stringify(JSON.parse(this.clientConfigs[index].scrape_config), null, 2);
      } catch (e) {
        this.clientConfigs[index].scrape_config = JSON.stringify({});
      }
    },
    normalizeExecutionDetailString(value) {
      return !isNullOrEmpty(value) ? value : 'Not executed yet';
    },
    normalizeExecutionDetailDate(date) {
      return !isNullOrEmpty(date) ? moment(date).format('YYYY-MM-DD HH:mm:ss') : 'Not executed yet';
    },
  },
};

</script>

<style scoped>
.text-area {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1% 1% 1% 1%;
}

.input-box {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.pretty-text-area {
  font-family: avenir, Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #333;
}

.card-header.collapsed:before {
  font-family: 'Glyphicons Halflings';
  content: "\25b6";
  float: right;
  transition: all 0.5s;
  margin-left: 25px;
}

.card-header.not-collapsed:before {
  font-family: 'Glyphicons Halflings';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  content: "\25b6";
  margin-left: 25px;
  float: right;
  transition: all 0.5s;
}
</style>
