<template>
  <b-container>
    <b-row class='mt-4'>
      <b-col>
        <b-card class='mb-4'>
          <template #header>
            <h5>Hooks Configuration</h5>
            <small>Create the JSON structure to be executed during the ingestion</small>
          </template>
          <b-col cols='12' class='mb-2' v-for='(hook, index) in clientHooks' :key="'hook-' + index">
            <b-row>
              <b-col cols='12'>
                <header class='card-header' v-b-toggle="'collapse-hook-' + hook.hook_id">
                  <b-button @click='removeConfig(index)' title='Remove file config' v-b-tooltip.hover
                    class='float-right' variant='link' style='min-width: unset; padding: 0' :id="'btn-remove-config-'">
                    <feather type='trash'></feather>
                  </b-button>
                  <b-row cols='12'>
                    <strong>{{ hook.code }}</strong>
                  </b-row>
                  <b-row cols='12' class='ml-1'>
                    <small>{{ hook.description }}</small>
                  </b-row>
                </header>
                <b-collapse :id="'collapse-hook-' + hook.hook_id" class='mt-2 mb-2' style=' border: 1px solid rgba(0, 0, 0, 0.125);
                  border-top: 0;
                  border-bottom-right-radius: 0.25rem;
                  border-bottom-left-radius: 0.25rem;'>
                  <b-row class="ml-1 mt-4 mb-2">
                    <b-col cols='3' class="mt-2">
                      <label>Active: </label>
                    </b-col>
                    <b-col cols='1'>
                      <b-form-checkbox v-model='hook.is_active'></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row :class="['ml-1', 'mt-4', 'mb-2']">
                    <b-col>
                      <h6>Hook Config: </h6>
                    </b-col>
                  </b-row>
                  <b-row :class="['ml-1', 'mr-1']">
                    <b-col cols='6'>
                      <TextareaAutosize class='text-area' :placeholder='hook.example' :ref="'myTextarea-' + index"
                        v-model='hook.config' :min-height='300' />
                    </b-col>
                    <b-col cols='6'>
                      <b-card class='mb-4 pretty-text-area'>
                        <pre>{{ hook.config | pretty }}</pre>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row class='ml-1 mt-1 mb-2'>
                    <b-col class='d-flex justify-content-start'>
                      <b-button class='mr-3' @click='prettyJson(index)'>JSON pretty</b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row class='ml-2 mr-2'>
                        <b-col>
                          <hr>
                        </b-col>
                      </b-row>
                      <b-row class='ml-2 mt-1 mb-2'>
                        <b-col>
                          <b-row class='mb-2'>
                            <b-col cols='12' class='mb-2'>
                              <h5>Metadata:</h5>
                            </b-col>
                          </b-row>
                          <b-row class='mb-4 ml-2'>
                            <b-col cols='3'>
                              <span>Created At:</span>
                            </b-col>
                            <b-col cols='9'>
                              <span>{{ hook.created_at }}</span>
                            </b-col>
                          </b-row>
                          <b-row class='mb-4 ml-2'>
                            <b-col cols='3'>
                              <span>Updated At:</span>
                            </b-col>
                            <b-col cols='9'>
                              <span>{{ hook.updated_at }}</span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-col>
            </b-row>
          </b-col>
          <div class='d-flex justify-content-end'>
            <b-button class='mr-3' v-b-modal.modal-1>Add hook config</b-button>
          </div>
          <b-modal id='modal-1' ref='hook-modal' title='Add Hook Config' hide-footer>
            <b-form-select v-model='selected' :options='hooksOptions'>
              <template v-slot:first>
                <option value='' disabled>- Select hook -</option>
              </template>
            </b-form-select>
            <div class='d-flex justify-content-end mt-3'>
              <b-button class='mr-3' @click='closeModal'>Cancel</b-button>
              <b-button class='mr-3' @click='addConfig'>Add</b-button>
            </div>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import moment from 'moment/moment';

export default {
  name: 'HooksConfig',
  props: {
    clientHooks: {
      type: Array,
    },
    hooks: {
      type: Array,
    },
    hooksOptions: {
      type: Array,
    },
    clientHooksToRemove: {
      type: Array,
    },
  },
  beforeMount() {
    this.visible = true;
  },
  mounted() {
    this.clientHooks.forEach((hook, i) => {
      hook.is_active = !!hook.is_active;
      this.prettyJson(i);
    });
  },
  data() {
    return {
      visible: false,
      showModal: false,
      selected: null,
    };
  },
  filters: {
    // eslint-disable-next-line consistent-return
    pretty(value) {
      try {
        return JSON.stringify(JSON.parse(value), null, 2);
      } catch (e) {
        return 'Invalid JSON Input!!';
      }
    },
  },
  methods: {
    removeConfig(index) {
      // add to remove array
      this.clientHooksToRemove.push(this.clientHooks[index]);

      // set disable to false on hook dropdown select
      // eslint-disable-next-line array-callback-return
      this.hooksOptions.map(h => {
        if (h.value === this.clientHooks[index].hook_id) {
          h.disabled = false;
        }
      });

      // delete hook from client hooks
      this.clientHooks.splice(index, 1);
    },
    closeModal() {
      this.$refs['hook-modal'].hide();
    },
    addConfig() {
      const newHook = this.hooks.find(h => h.id === this.selected);
      const hookObj = {
        client_id: this.$route.params.clientId,
        hook_id: newHook.id,
        config: JSON.stringify(newHook.example),
        is_active: false,
        code: newHook.code,
        description: newHook.description,
        example: JSON.stringify(newHook.example, null, 2),
        created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      // add new hook to client hooks
      this.clientHooks.push(hookObj);

      // set the hook disabled in select box
      // eslint-disable-next-line array-callback-return
      this.hooksOptions.map(h => {
        if (h.value === newHook.id) {
          h.disabled = true;
        }
      });

      // set null the dropdown value
      this.selected = null;

      // close select hook modal
      this.$refs['hook-modal'].hide();
      this.prettyJson(this.clientHooks.length - 1);
    },
    prettyJson(index) {
      try {
        this.clientHooks[index].config = JSON.stringify(JSON.parse(this.clientHooks[index].config), null, 2);
      } catch (e) {
        this.clientHooks[index].config = {};
      }
    },
  },
};

</script>

<style scoped>
.text-area {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1% 1% 1% 1%;
  min-height: 300px;
}

.pretty-text-area {
  font-family: avenir, Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #333;
}

.card-header.collapsed:before {
  font-family: 'Glyphicons Halflings';
  content: "\25b6";
  float: right;
  transition: all 0.5s;
  margin-left: 25px;
}

.card-header.not-collapsed:before {
  font-family: 'Glyphicons Halflings';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  content: "\25b6";
  margin-left: 25px;
  float: right;
  transition: all 0.5s;
}
</style>
